import React from 'react'
import SEO from '../../components/App/SEO';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';
const img = '/img/corporate/BlueMail_Corporate_Lock_v2.png'

const remoteLockScreenPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
        <SEO 
        postTitle='Remote Lock Screen Management | BlueMail App' 
        postDescription='Remote Lock Screen Management Ensure the security of your vital company information with BlueMail Plus&#8217;s Remote Lock Screen Management. From your corporate Dashboard, easily enforce lock screen password policies for your employees as well as force password expirations. Stay on...'
        postImage={img}
        postURL='corporate/remote-lock-screen-management'
        postSEO
        />
        <Navbar />
        <div className="remote-lock-screen-management-area container pt-120 pb-100">
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                    <img src={img} alt='BlueMail Corporate Remote Lock Screen Management' style={{width: '100%'}}/>
                </div>
                </div>
                <div className='row'>
                    <div className='col-12' style={{textAlign: 'center', marginTop: '1.8em'}}>
                        <h1>Remote Lock Screen Management</h1>
                        <hr />
                    </div>
                    <div className='col-12'>
                    <p className="mt-30 center-text">
                        Ensure the security of your vital company information with BlueMail Plus’s Remote Lock Screen Management. From your corporate Dashboard, easily enforce lock screen password policies for your employees as well as force password expirations. Stay on top of threats by monitoring multiple failed unlock attempts and remotely lock down your companies vital emails and communications.
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default remoteLockScreenPage

export const query = graphql`
query RemoteLockScreenPageeQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
